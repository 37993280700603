<template>
   <el-row type="flex" class="section">
      <el-col :span="4" class="search">
         <div class="search-box">
            <h4 class="title">日期选择</h4>
            <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="dateVal" type="date" placeholder="选择日期" @change="dateChange" :clearable="false"></el-date-picker>
         </div>
         <div class="search-box">
            <h4 class="title">房型筛选</h4>
            <!-- <el-select v-model="selectOption[idx].value" v-for="(select,idx) in selectOption" :key="idx" style="margin-bottom:10px" @change="changeRoomType">
               <el-option v-for="(item,option) in select.item" :key="option" :label="item.label" :value="item.value"></el-option>
            </el-select> -->
            <el-select v-model="selectOption[1].value" style="margin-bottom:10px" @change="changeRoomType">
               <el-option v-for="(item,option) in selectOption[1].item" :key="option" :label="item.label" :value="item.value"></el-option>
            </el-select>
         </div>
         <div class="search-box" v-if="roomCheck.length">
            <h4 class="title">房态筛选</h4>
            <div class="check-all">
               <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
               <span class="check-all-num">共{{roomTotalNum}}间</span>
            </div>
            <el-checkbox-group v-model="roomCheckState" @change="handleCheckedCitiesChange" class="check-box">
               <el-checkbox class="check-item" v-for="(state,idx) in roomCheck" :label="state" :key="idx" 
                  :style="idx === 0 ? { marginTop: '10px'}: idx === 4 || idx ===5 ? {borderBottom: '1px solid #DDD',marginBottom: '5px',paddingBottom: '5px' } :''">
                  <div class="color" :style="bg_color(state.label)"></div>
                  <span class="room_pl10">{{state.label}}({{state.num}}间)</span>
               </el-checkbox>
            </el-checkbox-group>
         </div>
         <div class="search-box">
            <el-button style="margin:0 20px 0 auto;display:block" type="plan" @click="resetSearch">重置搜索</el-button>
         </div>
      </el-col>
      <el-col :span="20">
         <el-scrollbar class="right-box">
            <div class="room" v-for="(room,idx) in roomData" :key="idx">
               <div class="room-tit" v-show="selectOption[0].value != 1">
                  {{room.roomTypeName}} <span class="sub-tit">（{{room.roomTotalNum}}）</span>
               </div>
               <div class="room-tit" v-show="selectOption[1].value == 1 && idx === 0">
                  <span class="sub-tit">总房间数（{{roomTotalNum}}）</span>
               </div>
               <div class="room-item">
                  <!-- 这里要判断订单的状态如果订单的状态为空房，则点击出现菜单选项，否则出现订单信息列表 -->
                  <el-popover
                     v-for="(rooms,roomsIdx) in room.rooms" :key="roomsIdx"
                     placement="right-start"
                     :close-delay="50"
                     width="260"
                     trigger="hover"
                     :disabled="!rooms.reserveMap.bookId">
                     <!-- 房态颜色 -->
                     <div class="item-info" :class="{'item-info-border':selectRoomList.includes(rooms.id)}" slot="reference" 
                        :style="(rooms.reserveMap.todayLeave && rooms.roomStateMap.state !== 'CHECKOUT' && rooms.reserveMap.bookId) ? bg_color('预离') :
                        rooms.reserveMap.checkInState === 'CHECKIN' ? bg_color('入住'):rooms.reserveMap.checkInState === 'NOT_CHECKIN'? bg_color('预定') :
                        (rooms.roomStateMap.state === 'RETAIN' || rooms.reserveMap.checkInState === 'CHECKOUT') ? bg_color('关房'):rooms.state === 'NOT_CLEAN_ROOM' ? bg_color('脏房') : bg_color('空房')"
                        @click="rooms.reserveMap.bookId ? orderDetails(rooms.reserveMap.reserveList[0].bookInfo.id) : selectRoom(rooms.id,rooms.roomStateMap,rooms.reserveMap)">
                        <div class="info-no">
                           {{rooms.roomNo}}
                           <span>
                              <img v-show="rooms.state === 'NOT_CLEAN_ROOM'" class="info-squalor" style="width:25px;height:25px;vertical-align: bottom;" src="@/assets/squalor.png" alt="">
                              <div v-show="rooms.reserveMap && rooms.reserveMap.checkInType === 'HOUR_ROOM'"  style="display: inline-block;width: 20px;height: 20px;border-radius: 20px; background-color: white;margin-left: 6px;" >
                                <img class="info-squalor" src="@/assets/pms-hour-room.png" alt="">
                              </div>
                           </span>
                        </div>
                        <div>{{ (rooms.reserveMap.hasOwnProperty('realName') && rooms.reserveMap.realName) || ""}}</div>
                        <div class="info-price">
                           <span>￥{{rooms.roomPrice}}</span>
                           <img class="debt" style="background-color: #ffd4b3;" src="@/assets/predict.png" alt="" v-if="rooms.reserveMap && rooms.reserveMap.checkInState === 'NOT_CHECKIN'">
                           <img class="debt" style="background-color: #ccc;" src="@/assets/pms-close-room.png" alt="" v-if="rooms.roomStateMap && rooms.roomStateMap.state === 'RETAIN'">
                           <img class="debt" src="@/assets/pms-arrears.png" v-if="rooms.reserveMap && rooms.reserveMap.amountsPayable > 0">
                        </div>
                        <span v-show="selectRoomList.includes(rooms.id)" class="el-icon-check item-check"></span>
                        <div v-show="selectRoomList[selectRoomList.length - 1] === rooms.id && !drawerBool && !rooms.reserveMap.bookId" class="menu">
                           <span class="el-icon-caret-left caret-left"></span>
                           <div v-show="!isCloseRoom">
                              <div class="menu-item" @click.stop="addRoomReserveInfo">新增</div>
                              <!-- <div class="menu-item" @click.stop="addRoomReserveInfo">入住</div> -->
                              <div class="menu-item" @click.stop="changPmsRoomState(rooms.state)">{{rooms.state !== 'NOT_CLEAN_ROOM' ? '转脏房' : '转净房'}}</div>
                              <div class="menu-item" @click.stop="closeroomBool = true">关房</div>
                           </div>

                           <div v-show="isCloseRoom" class="menu-item" @click.stop="addCloseRooms">开房</div>
                           <div class="menu-item" @click.stop="cancelSelectRomm">取消</div>
                        </div>
                     </div>
                     <el-scrollbar class="atrial-scrollbar popver-box">
                        <div class="popver" v-for="(reserve,num) in rooms.reserveMap.reserveList" :key="num">
                           <div class="popver-item">
                              <span class="el-icon-postcard m-right-10"></span>
                              <span class="m-right-10">{{reserve.bookInfo.realName}}</span>
                              <span>{{reserve.bookInfo.mobile}}</span>
                              <el-tag style="margin-left:auto;" size="mini" type="warning">{{ reserve.checkInState | filterCheckInState}}</el-tag>
                           </div>
                           <div class="popver-item">
                              <span class="el-icon-date" style="margin:2px 10px auto 0"></span>
                              <span>{{ reserve.checkInTime || reserve.startTime}} 入住 |<br/> {{ reserve.endTime}} 离店 | 共{{reserve.durationName}}</span>
                           </div>
                           <div class="popver-item border-b">
                              <span class="el-icon-paperclip m-right-10"></span>
                              <span>{{ reserve.bookInfo.channel | filterChannel(that) }}</span>
                           </div>
                           <div class="popver-item">
                              <span>订单总额：￥{{ reserve.bookInfo.orderTotalPrice }}</span>
                           </div>
                           <div class="popver-item border-b">
                              已收款：￥{{ reserve.bookInfo.actualAmount }}
                           </div>
                           <div class="popver-item">
                              备注：{{reserve.bookInfo.remark || "无"}}
                           </div>
                        </div>
                        <el-button style="display:block;margin:0 auto !important;" type="text" @click.stop="changPmsRoomState(rooms.state,rooms.id)">{{rooms.state !== 'NOT_CLEAN_ROOM' ? '转脏房' : '转净房'}}</el-button>
                     </el-scrollbar>
                  </el-popover>
               </div>
            </div>
         </el-scrollbar>
      </el-col>
      <!-- 关房 -->
      <el-dialog title="关房" :visible.sync="closeroomBool" width="500px" v-if="closeroomBool">
         <el-row>
            <el-col :span="2">备注</el-col>
            <el-col :span="21">
               <el-input class="m-bottom-20" type="textarea"  v-model="closeroomRemark" maxlength="200" rows="4" show-word-limit clearable></el-input>
            </el-col>
         </el-row>
         <span slot="footer" class="dialog-footer">
            <el-button @click="closeroomBool = false">取消</el-button>
            <el-button type="primary" @click="addCloseRooms">保存</el-button>
         </span>
      </el-dialog>

      <AddAtrialstate
         :drawerBool="drawerBool"
         :currentData="currentData"
         :roomsOptions="roomsOptions"
         @closeDrawer="closeDrawer"
         @selectCol="selectCol"
         @spliceCol="spliceCol"
         @Refresh="getRoomCalendarOneDay">
      </AddAtrialstate>
      <component :is="componentsList.OrderDetails" :orderDetailsBool="orderDetailsBool" :bookId="bookId" @closeOrderDetails="closeOrderDetails" @getRoomCalendars="getRoomCalendarOneDay"></component>
        <!-- <reveal-atrialstate></reveal-atrialstate> -->
   </el-row>
</template>

<script>
import { getRoomCalendarOneDay, changPmsRoomState, addCloseRooms, delCloseRooms, getRoomStateList } from "@/api/pms/roomCalendar"
import { axios_getConfigList } from '@/api/pms/atrialState/atrialState'
import { dateFactory } from '@/common/js/common'
import { pms } from '@/api/interface/pms';
import AddAtrialstate from "../../../components/local/atrialState/AddAtrialstate";
import OrderDetails from "../../../components/local/atrialState/OrderDetails";
import {mapState} from "vuex";
export default {
   name: "dayCalendar",
   components:{
      AddAtrialstate ,
      OrderDetails
   },
   data(){
      return{
         componentsList:{
            AddAtrialstate:'',
            OrderDetails:''
         },
         closeroomRemark:"", // 备注,
         closeroomBool:false,
         that:this,
         drawerBool:false,
         pickerOptions: {
            disabledDate(date){
               let zero = new Date().setHours(0, 0, 0, 0);
               if(date.getTime() < zero){
                  return true;
               }
               return false;
            }
         },
         dateVal:"", // 时间
         selectOption:[ // 筛选
            { // 排序
               value:"roomType",
               item:[
                  // { label:"按房型排序", value:"roomType" },
                  // { label:"按房间排序", value:1 },
                  // { label:"按分组排序", value:2 },
               ]
            },
            {
               value:'',
               item:[
                  { label:"全部房型", value:'' }
               ]
            }
         ],
         isIndeterminate:false,
         roomCheck:[],
         checkAll:false, // 全选状态
         roomCheckState:[], // 房态的选中状态
         selectRoomList:[], // 预订信息的房间ID
         currentData:[], //选中的房间详细信息，传给抽屉使用
         roomData:[],
         roomsOptions:[], // 弹窗中添加房型排房
         isCloseRoom:false, // 这个状态用来标记第一个每次第一个选中的是否为关房状态的房间，如果选择关房状态的
         roomTotalNum:0,
          // 订单详情 抽屉
         orderDetailsBool:false, // 是否显示 新增订单星期 抽屉
         bookId:'',              // 订单id
         nowTime:"",
         roomIdMap:{}, // 这个数据用来装每个房型ID与房间ID所处的数组的下标，可以避免循环

         colorList:[
            { name: '空房', color: '#0099ff', code: 'EMPTY' },
            { name: '脏房', color: '#cccccc', code: 'DIRTY' },
            { name: '预定', color: '#ffd4b3', code: 'CHECK_INT' },
            { name: '预离', color: '#c2b3ff', code: 'CHECK_OUT' },
            { name: '入住', color: '#b3deff', code: 'LIVE' },
            { name: '关房', color: '#cccccc', code: 'CLOSE' },
         ] // 颜色列表
      }
   },
   computed:{
      ...mapState(['dictData','hotelInfo']),
      bg_color(){
         return value => {
            for (let index = 0; index < this.colorList.length; index++) {
               if ( value === this.colorList[index].name ) {
                  return `background-color: ${this.colorList[index].color};`
               }
            }
         }
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if(oldVal && newVal.id !== oldVal.id){
            this.getRoomType()
            this.getRoomCalendarOneDay()
         }
      }
   },
   mounted(){
      this.dateVal = dateFactory.getDistanceToday(0,false)
      this.getRoomType()
      this.getRoomCalendarOneDay()
      this.getColorList()
   },
   methods:{
      // 获取色卡 
      getColorList() {
        const data = {
          hotelId: this.hotelInfo.id, // 酒店ID
        }
        axios_getConfigList(data).then(({ success, records }) => {
         if (success && records.length) {
            let list = [...this.colorList]
            // 处理请求回来的数据
            for (let inx = 0; inx < records.length; inx++) {
              if (list.some((item) => item.name === records[inx].name)) {
                list.some((item, index) => {
                  if (item.name === records[inx].name) {
                    list[index] = records[inx]
                  }
                })
              }
            }
            this.colorList = list
         }
        })
      },
      // 获取房型列表
      getRoomType(){
         const url = pms.getRoomTypeList;
         const param = {
            hotelId:this.hotelInfo.id,                            // 酒店ID
         }
         this.$axios.post(url, param, 'json').then((res) => {
            if (res.success) {
               let roomTypeList = [{label:"全部房型",value:""}]
               res.records.forEach( item => {
                  roomTypeList.push({ label:item.roomTypeName, value:item.id })
               })
               this.selectOption[1].item = roomTypeList;
            }
         })
      },
      // setCascaderDisable(fVal,rowData){
      //    this.roomsOptions[fVal].children.forEach( item => {
      //       item.disabled = item.dateList.includes(rowData.startTime)
      //    })
      // },
      dateChange(){
         this.getRoomStateList()
         this.getRoomCalendarOneDay()
      },
      // 显示订单详情 抽屉
      orderDetails(bookId){
         if(!this.componentsList.OrderDetails){ // 防止组件未挂载
            this.componentsList.OrderDetails = 'OrderDetails'
            this.$nextTick( () => {
               this.orderDetailsBool = true
               this.bookId = bookId
            })
         }else{
            this.orderDetailsBool = true
            this.bookId = bookId
         }
      },
      // 关闭 订单详情 抽屉
      closeOrderDetails(val,bookId){
         this.orderDetailsBool = val
         this.bookId = bookId
      },
      changeRoomType(e){ // 修改房型
         this.getRoomCalendarOneDay()
      },
      getRoomStateList(){
         let data = {
            hotelId:this.hotelInfo.id,
            beginDate:this.dateVal
         }
         getRoomStateList(data).then( res => {
            let roomCheck = [],
                state = {
                  EMPTY:"空房",
                  DIRTY:"脏房",
                  CHECK_INT:"预定",
                  CHECK_OUT:"预离",
                  LIVE:"入住",
                  CLOSE:"关房",
                  DEBT:"欠款",
                  OVERCHARGE:"多收",
                  HOURLY:"钟点房",
               }
            if(!this.roomCheck.length){
               for(let key in state){
                  roomCheck.push({label:state[key],value:key,num:res.records[key],check:false})
               }
               this.roomCheck = roomCheck
            }else{
               this.roomCheck.forEach( item => {
                  item.num = res.records[item.value]
               })
            }
            this.roomTotalNum = res.records.TOTAL
         })
      },
      addCloseRooms(){ // 添加关房
         if(!this.selectRoomList.length){
            this.$message.warn("请选择房间");
            return
         }
         let roomStateList = [] // 关房列表
         for(let roomType of this.roomData){
            if(roomStateList.length >= this.selectRoomList){ break }
            for(let rooms of roomType.rooms){
               if(roomStateList.length >= this.selectRoomList){ break }
               if(this.selectRoomList.includes(rooms.id)){
                  let selectData = {
                     roomTypeId:roomType.id,
                     roomId:rooms.id,
                     roomDateList:[this.dateVal],
                  }
                  roomStateList.push(selectData)
               }
            }
         }
         let data = {
            hotelId:this.hotelInfo.id,
            companyId:this.hotelInfo.storeId,
            roomStateList,
            remark:this.closeroomRemark,
         }
         let requestFn = !this.isCloseRoom ? addCloseRooms : delCloseRooms
         requestFn(data).then(res => {
            if(res.success){
               this.$message.success("操作成功")
               this.cancelSelectRomm()
               this.getRoomCalendarOneDay()
               this.closeroomBool = false
            }
         })

      },
      // 修改房间状态
      changPmsRoomState(state,roomId){
         let params = {
            ids:roomId ? [roomId] : this.selectRoomList,
            state:state !== 'NOT_CLEAN_ROOM' ? 'NOT_CLEAN_ROOM' : 'CLEAN_ROOM'
         }
         changPmsRoomState(params).then( res =>{
            if(res.success){
               this.$message.success("操作成功")
               this.selectRoomList.length = 0
            }
            this.getRoomCalendarOneDay()
         })
      },
      // 关闭右侧抽屉
      closeDrawer(){
         this.drawerBool = false
         this.currentData.length = 0
         this.cancelSelectRomm()
      },

      selectCol(item,id,index){
         if(id === "+"){
            this.currentData.push(item)
         }else{
            if(item.rooms && item.rooms.length){
               this.selectRoomList.splice(this.selectRoomList.indexOf(item.rooms[1]),1)
            }
            this.currentData.splice(index,1)
         }
      },

      spliceCol(val){
         let valMap = []
         val.forEach( vals => {
            valMap.push(vals.split("_")[0])
        /*
            let idx = this.roomIdMap[vals.split("_")[0]].index
            let room = this.roomData[idx].rooms
            room.forEach( rooms => {
               if(rooms.roomNo == vals.split("_")[1]){
                  valMap.push(rooms.id)
               }
            })
            */
         })
         this.selectRoomList.splice(0,this.selectRoomList.length,...valMap)
      },
      // 往抽屉中添加订房数据
      addRoomReserveInfo(){
         let roomList = this.selectRoomList
         this.roomData.forEach( (room,idx) => {
            room.rooms.forEach( (rooms,index) => {
            if( roomList.includes(rooms.id) ){
                  this.currentData.push({
                     keys:`${room.id}_${rooms.roomNo}_${this.dateVal}`,
                     startTime:this.dateVal,
                     endTime:this.dateVal,
                     duration:1,
                     rooms:[room.id,rooms.id],
                     roomRate:rooms.roomPrice,
                     price:rooms.roomPrice
                  })
               }
            })
         })
         this.drawerBool = true
      },

      resetSearch(){ // 重置搜索
         // this.selectOption.map( item => {
         //    item.value = item.item[0].value
         // })
         this.roomCheckState = []
         this.dateVal = dateFactory.getDistanceToday(0,false)
         this.handleCheckAllChange(false)
         this.checkAll = false
      },

      handleCheckAllChange(val) { // 房态全选
         this.roomCheckState = val ? this.roomCheck : [];
         this.isIndeterminate = false;
         this.getRoomCalendarOneDay()
      },

      handleCheckedCitiesChange(value) { // 房态单选
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.roomCheck.length;
        this.isIndeterminate = checkedCount > 0 && this.roomCheckState < this.roomCheck.length;
        this.getRoomCalendarOneDay()
      },

      cancelSelectRomm(){ // 清除选中的房型
         this.selectRoomList.splice(0,this.selectRoomList.length+1)
      },

      getRoomCalendarOneDay(){ // 获取今日房态
         let stateFilters = [],
             roomTypeId = this.selectOption[1].value;
         if(this.roomCheckState.length){ // 房态的选中列表
            this.roomCheckState.forEach( item => {
               stateFilters.push(item.value)
            })
         }
         if (stateFilters.length === 9 ) {
            stateFilters = []
         }
         let data = {
            hotelId:this.hotelInfo.id,
            beginDate:this.dateVal,
            companyId:this.hotelInfo.storeId,
            stateFilters,
            roomTypeId
         }
         getRoomCalendarOneDay(data).then( res => {
            this.roomData = res.records.roomTypeList
            res.records.roomTypeList.forEach( (item,index) => {
               this.roomIdMap[item.id] = {index,children:{}}
               let children = [] // new Map()
               item.rooms.map( ( room, idx ) => {
                  let key = room.id + "_" + this.dateVal // 房型ID_时间 用来匹配房间预定信息

                  room.reserveMap = res.records.reserveMap[key] || false
                  room.roomStateMap = res.records.roomStateMap[key] || false

                  children.push({value: room.id , label: room.roomNo, roomPrice:item.roomPrice, idx})
                  this.roomIdMap[item.id].children[room.id] = idx
               })
               this.roomsOptions.push({label:item.roomTypeName, value:item.id, children, idx:index})
            })
            this.nowTime = res.records.nowTime
            // this.selectOption[1].item = roomTypeList
            // 格式 房型id_入住日期
            this.getRoomStateList()
         }).catch( err => {
            this.roomData = []
         })
      },

      selectRoom(roomId,roomState,roomReserve){ // 选中房间
         roomState = roomState && roomState.state
         if(this.drawerBool || roomReserve.bookId || this.orderDetailsBool){ // 弹窗打开时不可操作
            return
         }
         // 数组为空时，可变更选中的类型状态 ，开房或关房
         if(!this.selectRoomList.length){ // 数组为空时，可变更状态
            this.isCloseRoom = roomState === 'RETAIN' ? true : false;
         }
         // this.isCloseRoom = (!this.selectRoomList.length && roomState === 'RETAIN') ? true : this.isCloseRoom;
         // isCloseRoom 为true 则表示第一次点击的房间状态为关房，此时点击未关房状态的房间则不做操作，反之亦然
         if((roomState === 'RETAIN' && !this.isCloseRoom) || (roomState !== 'RETAIN' && this.isCloseRoom)){
            return
         }
         if(!this.selectRoomList.includes(roomId)){
            this.selectRoomList.push(roomId)
         }else{
            let idx = this.selectRoomList.indexOf(roomId)
            this.selectRoomList.splice(idx,1)
         }
      }
   },
   filters: {
      filterRoomtypeTree(val,that){
        return that.maps.get(val)
      },
      filterCheckInState(val){
         switch (val){
            case 'NOT_CHECKIN' :
               return '已预订'
            case 'CHECKIN' :
               return '已入住'
            case 'CHECKOUT' :
               return '已退房'
            case 'CANCEL' :
               return '已取消'
            default :
               return '-'
         }
      },

      filterChannel(val,that){
         if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || val
      },
   }
}
</script>

<style scoped lang="scss">
   .search{
      padding-top:20px;
      padding-left:20px;
      box-shadow: 15px 0px 11px -11px #F1F1F1;
   }
   .search-box{
      margin-bottom:15px;
      .title{
         margin-bottom:10px;
      }
      .check-all{
         padding-bottom:20px;
         border-bottom:1px solid #DDD;
         margin-right:20px;
         display:flex;
         align-items:center;
         .check-all-num{
            margin-left:auto;
            font-size:12px;
         }
      }
      .check-box{
         line-height:30px;
         .check-item{
            width:46%;
            margin-right:0;
            .color{
               position: absolute;
               top: 10px;
               left: 21px;
               width: 10px;
               height: 10px;
            }
            .room_pl10{
               padding-left: 10px;
            }
            .room{
               padding-left: 0;
            }
         }
      }
   }
   .right-box /deep/ .el-scrollbar__wrap{
      overflow-x:hidden;
   }
   .right-box{
      padding-top:20px;
      height:700px;
      // overflow-y: auto;
   }
   .right-box .room:nth-last-child(1){
      padding-bottom:170px;
   }
   .room{
      padding:0 30px;
      .room-tit{
         font-size:16px;
         font-weight:bold;
         .sub-tit{
            font-size:16px;
            color:#999;
         }
      }
      .room-item{
         display:flex;
         flex-wrap: wrap;
         padding-top:10px;
         .item-info-border{
            box-shadow: 0 0 0px 3px #409eff !important;
            // box-shadow: 0 0 0px 3px #000 !important;
         }
         .item-info:focus{
            outline: none;
         }
         .item-info{
            display:flex;
            flex-direction: column;
            box-shadow: 0 0 5px 0 #CCC;
            padding:10px;
            border-radius:3px;
            width:158px;
            font-size:18px;
            height:80px;
            margin: 0 10px 10px 0;
            position:relative;
            color:#fff;
            .item-check{
               color: #fff;
               // color: #000;
               font-size:36px;
               font-weight:bold;
               position:absolute;
               top:30%;
               left:50%;
               transform: translateX(-50%);
            }
            .info-no{
               display:flex;
               align-items:center;
               margin-bottom:1px;
               justify-content: space-between;
               .info-squalor{
                  width:20px;
                  height:20px;
                  //margin-left:6px;
               }
            }
            .info-price :nth-child(2){
               margin-left:auto !important;
            }

            .info-price{
               margin-top:auto;
               display:flex;
               align-items:center;
               .debt{
                  width:18px;
                  height: 18px;
                  line-height: 18px;
                  text-align: center;
                  background-color: red;
                  color: white;
                  margin-left:5px;
               }
            }
            .menu{
               position:absolute;
               top:0;
               right:-40%;
               z-index:2;
               cursor:pointer;
               background:#444;
               border-radius:3px;
               font-size:14px;
               .caret-left{
                  position:absolute;
                  top:20px;
                  font-size:22px;
                  left:-14px;
                  color:#444;
               }
               .menu-item{
                  width:45px;
                  border-radius:3px;
                  padding:10px;
                  background:#444;
                  color:#F9F9F9;
                  text-align: center;
               }
               .menu-item:hover{
                  background:rgba(0,0,0,.9);
               }
            }
         }
      }
   }
   .popver-box{
      height:300px;
      .popver{
         padding:10px 0;
         border-bottom:1px solid #E0E0E0;
         .popver-item{
            display:flex;
            align-items:center;
            padding:4px 0;
         }
         .border-b{
            border-bottom:1px dashed #E1E1E1;
            padding-bottom:10px;
            margin-bottom:10px;
         }
      }
   }
</style>
